import { defineComponent, watch, ref } from '@vue/composition-api';
import FormIncrementedInput from '@/shared/components/form/FormIncrementedInput.vue';
export default defineComponent({
    components: {
        FormIncrementedInput
    },
    props: {
        value: {
            type: Number,
            default: 0
        },
        title: {
            type: [String, Object],
            default: ''
        },
        firstLabel: {
            type: [String, Object],
            default: ''
        },
        firstLabelDescription: {
            type: [String, Object],
            default: ''
        },
        secondLabel: {
            type: [String, Object],
            default: ''
        },
        secondLabelDescription: {
            type: [String, Object],
            default: ''
        },
        thirdLabel: {
            type: [String, Object],
            default: ''
        },
        thirdLabelDescription: {
            type: [String, Object],
            default: ''
        },
        limitPlaceholder: {
            type: [String, Object],
            default: ''
        },
        labelDescription: {
            type: Boolean,
            default: false
        },
        form: {
            type: Object,
            default: () => { }
        },
        limit: {
            type: String,
            default: ''
        },
        hasIncrementedInput: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const selectedOption = ref(props.value);
        watch(() => props.value, () => {
            selectedOption.value = props.value;
            emit('input', selectedOption.value);
        }, { immediate: true });
        const switchOption = (optionId) => {
            if (!props.secondLabel)
                return;
            selectedOption.value = optionId;
            if (props.limit !== 'decisionTime')
                emit('updatedLimit', 0);
            emit('input', selectedOption.value);
        };
        return {
            selectedOption,
            switchOption
        };
    }
});
