import { defineComponent, ref, watch, onMounted, onUpdated } from '@vue/composition-api';
export default defineComponent({
    props: {
        value: {
            type: Number,
            default: 0
        },
        inline: {
            type: Boolean,
            default: false
        },
        bottomLess: {
            type: Boolean,
            default: false
        },
        label: {
            type: [String, Object],
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ''
        },
        minValue: {
            type: Number,
            default: 0
        },
        itemsName: {
            type: [String, Object],
            default: ''
        },
        step: {
            type: Number,
            default: 1
        },
        placeholder: {
            type: [String, Object],
            default: ''
        },
        maxValue: {
            type: Number,
            default: 99999
        },
        maxLength: {
            type: Number,
            default: 255
        },
        disabled: {
            type: Boolean,
            default: false
        },
        textValueWhenZero: {
            type: Boolean,
            default: false
        },
        inputNumberMinZero: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const inputBlock = ref(null);
        const inputValue = ref(props.value);
        const itemsOffset = ref(0);
        watch(() => props.value, () => {
            inputValue.value = props.value;
            emit('input', inputValue.value);
        }, { immediate: true });
        watch(() => inputValue.value, () => {
            emit('input', inputValue.value);
        }, { immediate: true });
        const setItemsOffset = () => {
            const inputEl = inputBlock.value.$el.getElementsByTagName('input')[0];
            const hiddenEl = document.getElementById('hidden-div');
            if (hiddenEl) {
                hiddenEl.innerText = inputEl.value;
                itemsOffset.value = hiddenEl.clientWidth;
            }
        };
        const decrement = () => {
            if (inputValue.value > props.minValue)
                inputValue.value -= props.step;
            emit('input', inputValue.value);
            emit('onDecrement', inputValue.value);
        };
        const increment = () => {
            const res = Number(inputValue.value) + props.step;
            if (props.maxValue && res > props.maxValue)
                return;
            inputValue.value = res;
            emit('input', inputValue.value);
        };
        const mouseHoldInterval = ref(null);
        /**
         * Keep incrementing/decrementing the value while the mouse button is held down
         * @param method 0 - increment, 1 - decrement
         */
        const onMouseDown = (method) => {
            if (method === 0) {
                increment();
                mouseHoldInterval.value = setInterval(increment, 500);
            }
            else {
                decrement();
                mouseHoldInterval.value = setInterval(decrement, 500);
            }
        };
        /**
         * Stop incrementing/decrementing the value when the mouse button is released
         */
        const onMouseUp = () => {
            clearInterval(mouseHoldInterval.value);
        };
        onMounted(() => {
            if (props.itemsName)
                setItemsOffset();
        });
        onUpdated(() => {
            if (!props.itemsName)
                itemsOffset.value = 0;
            else
                setItemsOffset();
        });
        return {
            inputBlock,
            inputValue,
            itemsOffset,
            decrement,
            increment,
            onMouseDown,
            onMouseUp
        };
    }
});
